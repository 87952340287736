import React, { useEffect } from "react";
import styled from "styled-components";

// Styled-components styles for the page
const ComingSoonContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GradientText = styled.h1`
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 58px; /* Adjust the font size as needed */
  text-align: center;
  font-family: Cursive", "Lucida", cursive, sans-serif;
`;

const ComingSoonPage = () => {
  useEffect(() => {
    // Initialize particles.js
    window.particlesJS("particles", {
      particles: {
        number: {
          value: 80, // Adjust the number of particles
        },
        size: {
          value: 3, // Adjust the size of particles
        },
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: "repulse", // Change the interaction mode on hover
          },
        },
      },
    });
  }, []);

  return (
    <ComingSoonContainer>
      <GradientText>Details Coming Soon ...</GradientText>
      <div
        id="particles"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></div>
    </ComingSoonContainer>
  );
};

export default ComingSoonPage;
