import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { FaHome } from "react-icons/fa";
import hydro_ps1 from "./utils_competitions/Hydro_PS1.png";
import str_ps1 from "./utils_competitions/Str_PS1.png";
import geo_ps1 from "./utils_competitions/geo_ps_1.png";

import "animate.css";
import TrackVisibility from "react-on-screen";
import HeadingComponent from "./Heading";

export const Projects = () => {
  const structural = [
    {
      title: "Spaghetti Bridge Design Challenge",
      description:
        "Get ready to exhibit your analytical and design prowess in the Bridge Design Competition. Craft tension-compression members to maximize the load bearing capability of your truss bridge . Join us for this challenge emphasizing innovation and structural efficiency!",
      last_date: "5th January",
      imgUrl: str_ps1,
      ps_link:
        "https://drive.google.com/file/d/1PJRIGj3FMae7Ov23s2a7oqeIaeiTliZI/view?usp=drive_link",
      reg_link:
        "https://unstop.com/p/spaghetti-bridge-design-challenge-sankalan-24-iit-kanpur-society-of-civil-engineers-iit-kanpur-846090",
    },
    {
      title: "Optimising Water Resources ",
      description:
        "As we know that water scarcity is a pressing global issue, especially in the context of agricultural sustainability. Gear up your sustainable mindset and develop an innovative solution to optimise water resource usage for sustainable agriculture. Pull up your socks and show us your creative sustainable models.",
      last_date: "5th January",
      imgUrl: hydro_ps1,
      ps_link:
        "https://drive.google.com/file/d/1NwzSWxZW7HdzaJ_e1NLn3KX0_pC9e9ea/view?usp=sharing",
      reg_link:
        " https://unstop.com/p/optimising-water-resources-for-sustainable-agriculture-sankalan-24-civil-conclave-iit-kanpur-848899",
    },
    {
      title: "Remote Satellite Design Challenge",
      description:
        "Exclusive opportunity for all the geoinformatics enthusiasts to showcase your talent via practical implementation. Build a remote satellite model that measures any essential climate variable (ECV) and pounce upon the opportunity to compete with like minds across the country.",
      last_date: "10th January",
      imgUrl: geo_ps1,
      ps_link:
        "https://drive.google.com/file/d/1kB2qnH4ayGYoy_bEaBE3hYY8DdTuqASj/view?usp=sharing",
      reg_link:
        " https://unstop.com/p/remote-satellite-design-challenge-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-850526",
    },
  ];

  return (
    <section className="project" id="projects" style={{ width: "100vw" }}>
      {/* <HeadingComponent /> */}
      <div
        style={{
          position: "fixed",
          top: "10px",
          left: "10px",
          padding: "10px",
          background: "rgba(59, 116, 148, 0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <a href="/">
          <FaHome style={{ fontSize: "40px", color: "white" }} />
        </a>
      </div>
      <Container style={{ width: "100vw" }}>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>COMPETITIONS</h2>
                  <p></p>
                  <Row>
                    {structural.map((structural, index) => {
                      return <ProjectCard key={index} {...structural} />;
                    })}
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

// <Tab.Container id="projects-tabs" defaultActiveKey="first">
//   <Nav
//     variant="pills"
//     className="nav-pills mb-5 justify-content-center align-items-center"
//     id="pills-tab"
//   >
//     <Nav.Item style={{ width: "33.333%" }}>
//       <Nav.Link eventKey="first">Structural</Nav.Link>
//     </Nav.Item>
//     <Nav.Item style={{ width: "33.333%" }}>
//       <Nav.Link eventKey="second">Hydraulics</Nav.Link>
//     </Nav.Item>
//     <Nav.Item style={{ width: "33.333%" }}>
//       <Nav.Link eventKey="third">Transportation</Nav.Link>
//     </Nav.Item>

//     {/* Additional Tabs */}
//     {/* <Nav.Item
//                       style={{ width: "50%", border: "1px solid white" }}
//                     >
//                       <Nav.Link eventKey="fourth">Fourth Tab</Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item
//                       style={{ width: "50%", border: "1px solid white" }}
//                     >
//                       <Nav.Link eventKey="fifth">Fifth Tab</Nav.Link>
//                     </Nav.Item> */}
//     <Nav.Item style={{ width: "100%", border: "1px solid white" }}>
//       <Nav.Link eventKey="sixth">More Coming Soon</Nav.Link>
//     </Nav.Item>
//   </Nav>
//   <Tab.Content
//     id="slideInUp"
//     className={isVisible ? "animate__animated animate__slideInUp" : ""}
//   >
//     <Tab.Pane eventKey="first">
//       <Row>
//         {structural.map((structural, index) => {
//           return <ProjectCard key={index} {...structural} />;
//         })}
//       </Row>
//     </Tab.Pane>
//     <Tab.Pane eventKey="second">
//       <Row>
//         {hydraulics.map((hydraulics, index) => {
//           return <ProjectCard key={index} {...hydraulics} />;
//         })}
//       </Row>
//     </Tab.Pane>
//     <Tab.Pane eventKey="third">
//       <Row>
//         <h1 style={{ textAlign: "center" }}>Coming Soon..</h1>
//         {/* {transportation.map((transportation, index) => {
//                           return (
//                             <ProjectCard key={index} {...transportation} />
//                           );
//                         })} */}
//       </Row>
//     </Tab.Pane>

//     {/* Additional Tab Panes */}
//     <Tab.Pane eventKey="fourth">
//       <Row>{/* Content for the fourth tab */}</Row>
//     </Tab.Pane>
//     <Tab.Pane eventKey="fifth">
//       <Row>{/* Content for the fifth tab */}</Row>
//     </Tab.Pane>
//     <Tab.Pane eventKey="sixth">
//       <Row>
//         <h1 style={{ textAlign: "center" }}>
//           Other Domain Competitions Coming Soon..
//         </h1>
//       </Row>
//     </Tab.Pane>
//   </Tab.Content>
// </Tab.Container>;
