import React, { useState, useEffect } from "react";
import cricket from "./cricket.jpg";
import bicycling from "./bicycling.jpg";
import donation from "./donation.jpg";
import nature from "./nature.jpg";
import running from "./running.jpg";
import { FaHome } from "react-icons/fa";
import { getMarginLeft } from "../../utils/getMarginLeft";

const Card = ({ imgSrc, title, description }) => {
  const [isHovered, setHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const cardStyle = {
    width: "280px",
    height: "400px",
    borderRadius: "15px",
    // padding: "1.5rem",
    background: "white",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    transition: "0.4s ease-out",
    boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.5)",
    // transform: "translateY(20px)",
    marginBottom: "20px",
  };
  const imgStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "15px",
  };
  const beforeStyle = {
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    background: "rgba(0, 0, 0, 0.6)",
    zIndex: "2",
    transition: "0.5s",
    opacity: isHovered ? "1" : "0",
  };

  const infoStyle = {
    position: "relative",
    zIndex: "3",
    color: "white",
    opacity: isHovered ? "1" : "0",
    transform: isHovered ? "translateY(0px)" : "translateY(30px)",
    marginTop: "auto",
    marginBottom: "20px",
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={imgSrc} alt={title} style={imgStyle} />
      <div style={beforeStyle}></div>
      <div style={infoStyle}>
        <h1>{title}</h1>
        <p>{description}</p>
        {/* <button
          style={{
            padding: "0.6rem",
            outline: "none",
            border: "none",
            borderRadius: "3px",
            background: "white",
            color: "black",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "0.4s ease",
          }}
        >
          Read More
        </button> */}
      </div>
    </div>
  );
};

export const Pre_Events = () => {
  const headingStyle = {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    color: "#3498db",
    fontSize: "3.5em",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    margin: "20px 0",
    marginTop: "40px",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap", // Allow cards to wrap to the next line
    margin: "50px auto",
    marginLeft: "85px",

    "@media (max-width: 768px)": {
      marginLeft: "0px",
    },
  };
  const divStyle = {
    marginLeft: "85px", // Default value for screens less than 768px

    "@media (min-width: 768px)": {
      marginLeft: "85px",
    },
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: "60px",
          left: "20px",
          padding: "10px",
          background: "rgba(59, 116, 148, 0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <a href="/">
          <FaHome style={{ fontSize: "40px", color: "#0092ca" }} />
        </a>
      </div>
      <h1 style={headingStyle}>Pre-Events</h1>
      <br />

      <div style={containerStyle}>
        <Card
          imgSrc={cricket}
          style={divStyle}
          title="Cricket-Tournament"
          description="The first ever cricket tournament for all the cricket enthusiasts in the Civil Engineering department of IITK was organized as part of the pre-events of SANKALAN’24. We witnessed great participation from the entire CE community and hope to conduct it in future as well."
        />

        <Card
          imgSrc={bicycling}
          title="Bicycling Event"
          description="Bicycling is not just going to lectures and labs, it’s way more than that. To invigorate everyone with excitement, a BICYCLING event will be organized for the campus junta as we proceed towards SANKALAN’24."
        />

        <Card
          imgSrc={running}
          title="Running Event"
          description="Running not only enriches your physical health but also boosts your mental well-being. As time is passing and we are heading towards our main event SANKALAN’24, we are thrilled to organize RUNNING EVENT’23 for the entire student community."
        />
        <Card
          imgSrc={nature}
          title="Nature Awareness Drive"
          description={`"Nature is not a place to visit, it is home." –Gary Snyder. In today’s fast developing times, the idea to preserve our nature is slowly blurring from our minds. We are overwhelmed to organise NATURE AWARENESS EVENT’23 that aims at making people aware about the importance of nature in our lives.`}
        />

        <Card
          imgSrc={donation}
          title="Donation Drive"
          description={`“We can’t help everyone, but…Everyone can help someone.”– Dr. Loretta Scott. As we all know that everyone is not blessed to have access to all the commodities. DONATION DRIVE EVENT’24 aims at providing the needy with basic requirements with the support of the entire campus community.`}
        />
      </div>
    </div>
  );
};
