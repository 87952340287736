import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-size: 13vmin;
  height: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
  mix-blend-mode: screen;

  &.invert {
    span {
      color: black;

      &::before {
        -webkit-text-stroke: 0.1em var(--color);
      }
    }
  }
`;

const Span = styled.span`
  --color: #014a82;
  font-family: Impact, "Anton", Haettenschweiler, "Arial Narrow Bold",
    sans-serif;
  font-weight: 700;
  font-style: italic;
  display: block;
  position: absolute;
  color: #014a82;
  letter-spacing: -0.005em;

  &::before,
  &::after {
    content: attr(data-text);
    display: block;
    position: relative;
    padding: 0 0.1em;
    z-index: 1;
  }

  &::before {
    position: absolute;
    -webkit-text-stroke: 0.1em black;
    z-index: 0;
  }

  &:first-child {
    transform: translate(-0.255em, -0.25em);
  }

  &:last-child {
    --color: #014a82;
    transform: translate(0.255em, 0.25em);
  }
`;

const HeadingOne = ({ text1, text2 }) => {
  return (
    <Wrapper className="invert">
      <Span data-text={text1} />
      <Span data-text={text2} />
    </Wrapper>
  );
};

export default HeadingOne;
