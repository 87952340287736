import React from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  0% {
    transform: translateX(500px) scale(.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const expand = keyframes`
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  animation: ${expand} 0.8s ease forwards;
  background-color: var(--secondary-color);
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: all 0.8s ease;
`;

const ContentContainer = styled.div`
  width: 50%;
`;

const InnerContentContainer = styled.div`
  width: 80%;
  margin-left: 80px;
`;

const OuterImgContainer = styled.div`
  margin: 50px;
  width: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  animation: ${slideIn} 1.5s ease-in-out forwards;
`;

const Paragraph = styled.p`
  line-height: 28px;
  transform: translateY(300px);
  animation: ${slideUp} 0.8s ease-in-out forwards 0.8s;
`;

const Title = styled.div`
  overflow: hidden;
  height: auto;
`;

const Heading1 = styled.h1`
  font-size: 40px;
  color: var(--contrast-color);
  margin-bottom: 20px;
  transform: translateY(100px);
  animation: ${slideUp} 0.8s ease forwards 0.5s;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -10;
  background-color: var(--contrast-color);
`;

const About = () => {
  return (
    <Container>
      <ContentContainer>
        <InnerContentContainer>
          <Title>
            <Heading1>Hello World</Heading1>
          </Title>
          <div className="par">
            <Paragraph>
              Cupiditate alias odio omnis minima veritatis saepe porro,
              repellendus natus vitae, sequi exercitationem ipsam, qui possimus
              sit eveniet laborum sapiente quisquam quae neque velit?
            </Paragraph>
          </div>
        </InnerContentContainer>
      </ContentContainer>
      <OuterImgContainer>
        <div className="img-inner">
          <Image
            src="https://images.unsplash.com/photo-1517911041065-4960862d38f0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80"
            alt=""
          />
        </div>
      </OuterImgContainer>
      <Overlay />
    </Container>
  );
};

export default About;
