import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const AboutSankalan = () => {
  return (
    <section className="project" id="campusam">
      <Container>
        <Row className="align-items-center">
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2
                    style={{
                      fontFamily: "Times New Roman",
                      fontWeight: "600",
                    }}
                  >
                    About Sankalan
                    {/* <span
                      style={{
                        background:
                          "linear-gradient(#012a34, #040f1a, #03323d)",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      Sankalan
                    </span> */}
                  </h2>
                  <p
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "18px",
                      textAlign: "justify",
                    }}
                  >
                    The Society of Civil Engineers, IIT Kanpur is hosting its
                    flagship event The Civil Engineering Conclave Sankalan. This
                    two-day event promises an immersive and enriching
                    experience, providing a platform for knowledge exchange,
                    hands-on learning, and collaboration in the realm of civil
                    engineering. Sankalan will feature a series of interactive
                    workshops designed to equip participants with the latest
                    advancements in civil engineering methodologies. Renowned
                    experts from India and abroad will grace the event with
                    their insights, sharing their perspectives on the pivotal
                    role of civil engineering in addressing global challenges.
                    The event also features multiple competitions tackling
                    real-life problems, and a captivating technology exhibition
                    area will showcase cutting-edge innovations, fostering a
                    spirit of innovation and technological exploration.
                    Sankalan's overarching objective is to establish itself as a
                    central hub for knowledge sharing, innovation, and
                    collaboration in the field of civil engineering. By bringing
                    together a diverse range of perspectives and fostering a
                    dynamic exchange of ideas, the conclave aims to shape the
                    future of civil engineering and contribute to the betterment
                    of society.
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img
        className="background-image-right"
        src={colorSharp2}
        alt="Background"
      /> */}
    </section>
  );
};
