import React, { useEffect, useState } from "react";
import { FaHandHoldingHeart, FaHome } from "react-icons/fa";
import "./alum.css";
import step1 from "./Pay/step1.png";
import step2 from "./Pay/step2.png";
import step3 from "./Pay/step3.png";
import step4 from "./Pay/step4.jpg";
import step5 from "./Pay/step5.jpg";

import letter from "./HOD-Letter_page.jpg";

function AlumSupport() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="about" id="about">
      <div
        style={{
          position: "fixed",
          top: "60px",
          left: "20px",
          padding: "10px",
          background: "rgba(59, 116, 148, 0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <a href="/">
          <FaHome style={{ fontSize: "40px", color: "#00FFFF" }} />
        </a>
      </div>

      <h1
        style={{
          fontFamily: "'Montserrat', sans-serif",
          textAlign: "center",
          marginTop: "60px",
        }}
        data-aos="fade-up"
        data-aos-delay="300"
      >
        SUPPORT FOR SANKALAN
      </h1>

      <div
        style={{
          // background: "rgba(59, 116, 148, 0.3)",
          // border: "3px solid rgba(133, 133, 133, 0.5)",
          // borderRadius: "20px",
          // padding: "1% 2%",
          overflow: "hidden",
        }}
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: windowWidth < 768 ? "column" : "row",
          }}
        >
          <div
            className="info"
            style={{
              background: "rgba(59, 116, 148, 0.3)",
              border: "1.2px solid rgba(133, 133, 133, 0.5)",
              borderRadius: "10px",
              padding: "1%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              maxWidth: windowWidth < 768 ? "100%" : "90vw",

              boxSizing: "border-box",
              marginRight: 4,
              textAlign: "justify",
            }}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h3 style={{ fontFamily: "'Montserrat', sans-serif" }}>
              About <span>Sankalan, IIT KANPUR</span>
            </h3>
            <p
              style={{
                fontFamily: "'Montserrat', sans-serif",
                marginBottom: "0",
                textAlign: "justify",
              }}
            >
              Sankalan, the inaugural edition of the Civil Engineering Conclave
              at IIT Kanpur, is scheduled for January 27th and 28th, 2024. This
              event is designed to cultivate innovation and collaboration within
              the civil engineering community, featuring competitions,
              workshops, and discussions.
            </p>
            <p
              style={{
                fontFamily: "'Montserrat', sans-serif",
                textAlign: "justify",
              }}
            >
              While corporate support is secured, your contribution, regardless
              of size, is vital for seamless execution. Your involvement will
              elevate the student experience and showcase your commitment to our
              department's growth and excellence.
            </p>
            <p
              style={{
                fontFamily: "'Montserrat', sans-serif",
                textAlign: "justify",
              }}
            >
              Support Sankalan and shape the future of civil engineering at IIT
              Kanpur. Your contribution matters!
            </p>
            <p>
              <strong>HOD Letter</strong> :{" "}
              <a
                href="https://drive.google.com/file/d/1j3okHuWXzydcvvSqowYRVoXl8zHb-tne/view?usp=drive_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click Here
              </a>{" "}
            </p>
            {/* Additional paragraphs go here */}
          </div>

          {/* <div
            className="letter"
            style={{
              background: "rgba(59, 116, 148, 0.3)",
              border: "1.2px solid rgba(133, 133, 133, 0.5)",
              borderRadius: "10px",
              padding: "0%",
              overflow: "hidden",
              width: windowWidth < 768 ? "100%" : "45%",
              height: windowWidth < 768 ? "100%" : "40%",
              maxWidth: windowWidth < 768 ? "100%" : "1",
              boxSizing: "border-box",
              marginTop: windowWidth < 768 ? "20px" : "0",
              marginLeft: windowWidth < 768 ? "auto" : "0",
            }}
          >
            <img
              src={letter}
              style={{
                width: "69%",
                height: "60%",
                marginLeft: "85px",
                marginBottom: "10px",
              }}
              alt="HOD-Letter"
            ></img>
          </div>*/}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.2%",
        }}
      >
        <button
          style={{
            background: "#00FFFF",
            color: "#000",
            padding: "15px 30px",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            window.open(
              "https://www.onlinesbi.sbi/sbicollect/icollecthome.htm",
              "_blank",
              "noopener noreferrer"
            );
          }}
        >
          Contribute Here
          <FaHandHoldingHeart
            style={{ marginLeft: "10px", fontSize: "24px" }}
          />
        </button>
      </div>

      <div
        style={{
          background: "rgba(59, 116, 148, 0.3)",
          border: "3px solid rgba(133, 133, 133, 0.5)",
          borderRadius: "20px",
          width: "90vw",
          padding: "1% 2%",
          margin: "2% 0",
          marginRight: "45px",
          overflow: "hidden",
        }}
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <h3 style={{ fontFamily: "'Montserrat', sans-serif" }}>
          How to <span>Pay ?</span>
        </h3>
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: "1px",
            fontSize: "20px",
          }}
        >
          Step 1: Click on “Educational Institutions
          <div style={{ width: "50%", marginTop: "10px" }}>
            <img src={step1} style={{ width: "100%" }} alt="Step 1"></img>
          </div>
        </p>
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: "1px",
            fontSize: "20px",
          }}
        >
          Step 2: Select “Indian Institute of Technology Kanpur” in Educational
          Institution
          <div style={{ width: "50%", marginTop: "10px" }}>
            <img src={step2} style={{ width: "100%" }} alt="Step 2"></img>
          </div>
        </p>
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: "1px",
            fontSize: "20px",
          }}
        >
          Step 3: Select “CDTE WORKSHOPS” in Payment Category
          <div style={{ width: "50%", marginTop: "10px" }}>
            <img src={step3} style={{ width: "100%" }} alt="Step 3"></img>
          </div>
        </p>
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: "1px",
            fontSize: "20px",
          }}
        >
          Step 4: Complete the form and compulsorily mention Course Title,
          Course Code and Course Duration as SANKALAN , IITK/CCE/23-24/073 and
          27/01/24 to 28/01/24 respectively.
          <div style={{ width: "50%", marginTop: "10px" }}>
            <img src={step4} style={{ width: "100%" }} alt="Step 4"></img>
          </div>
        </p>
        <p
          style={{
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: "1px",
            fontSize: "20px",
          }}
        >
          Step 5: Verify all the details and proceed further for Payment.
          <div style={{ width: "50%", marginTop: "10px" }}>
            <img src={step5} style={{ width: "100%" }} alt="Step 5"></img>
          </div>
        </p>
      </div>
    </section>
  );
}

export default AlumSupport;
