import React from "react";

import "./Workshops.css";
import { getMarginLeft } from "../../utils/getMarginLeft";

function Workshops({ isMobileView, isExpanded }) {
  const handleGetWorkshops = () => {
    window.open(
      "https://unstop.com/p/sankalan-24-iit-kanpur-civil-engineering-conclave-society-of-civil-engineers-iit-kanpur-844899",
      "_blank"
    );
  };
  const handleWorkshop1 = () => {
    window.open(
      "https://docs.google.com/document/d/1n0hlHoF_UC59-6ZkgFia7PuuU7bBzPsd0jMM1lZxie0/edit?usp=sharing",
      "_blank"
    );
  };
  const handleWorkshop2 = () => {
    window.open(
      "https://docs.google.com/document/d/11Eip_gfyDf6KVCwqwLxxCIP0eg3tutGboESno2KZ1FQ/edit",
      "_blank"
    );
  };

  return (
    <div
      className="Workshops"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <div className="guidelines-Workshops">
        <h4
          style={{
            fontWeight: "550",
            marginLeft: "1.5vh",
            marginBottom: "1.5vh",
          }}
        >
          Workshops Guidelines:
        </h4>

        <ul>
          <li>
            <strong>Registration Guidelines:</strong>
            <ul>
              <li>
                To be eligible for incentives, please complete the registration
                process by paying the registration fees and attending the
                workshop on both days.
              </li>
              <li>
                If you require accommodation, kindly find the details by
                clicking{" "}
                <a href="/accommodation">
                  <strong>here</strong>
                </a>
                .
              </li>
            </ul>
          </li>
          <li>
            <strong>Incentives:</strong>
          </li>
          <ul>
            <li>Certificate</li>
            <li>Goodies</li>
          </ul>
          <li>
            <strong>Requirements:</strong>
          </li>
          <ul>
            <li>Laptop</li>
            <li>Charger </li>
          </ul>
          <li>
            <strong>Fees:</strong> NA (Only the participants who have registered
            for Sankalan are eligible for Workshops)
          </li>
          <li>
            <strong>Venue:</strong>
          </li>
          <ul>
            <li>IIT Kanpur</li>
          </ul>
        </ul>
      </div>
      <div className="workshops-cards">
        <div className="workshop-card">
          <h3
            style={{
              fontWeight: "550",
              marginLeft: "1.5vh",
              marginBottom: "1.5vh",
              textAlign: "center",
            }}
          >
            Structural Design Practices using Soft Computing Applications
          </h3>
          <p>
            {" "}
            <strong>
              Mr. Rohit Yadav (Managing Director, TeXel Consulting Engineers
              Pvt. Ltd.)
            </strong>
          </p>
          <div className="details-Workshops">
            <ul>
              <li>Introduction to Structural design and analysis</li>
              <li>Geometrical modelling of structures</li>
              <li>Case study of projects</li>
              <li>BIS used in structural design practices</li>
              <li>Case study of projects</li>
              <li>Discussions</li>
            </ul>
          </div>

          <div className="buttons-workshops">
            <button className="register-btn-workshop" onClick={handleWorkshop1}>
              <strong>Learn More</strong>
            </button>
            <button
              className="register-btn-workshop"
              onClick={handleGetWorkshops}
            >
              <strong>Register Here</strong>
            </button>
          </div>
        </div>
        <div className="workshop-card">
          {" "}
          <h3
            style={{
              fontWeight: "550",
              marginLeft: "1.5vh",
              marginBottom: "1.5vh",
              textAlign: "center",
            }}
          >
            Enabling Smart Cities: Comprehensive Worshop on AI-ML
          </h3>
          <p>
            <strong>
              Ms. Sanjita Prajapati (Research Assistant at IIT Kanpur)
            </strong>
          </p>
          <div className="details-Workshops">
            <ul>
              <li>Introduction and Subsets of AI</li>
              <li>Academic and Industry Difference</li>
              <li>CVPR, Tensorflow, PyTorch, etc.</li>
              <li>Applied AI</li>
              <li>AI in Civil Engineering with Problem Statements</li>
              <li>And many more exciting topics</li>
            </ul>
          </div>
          <div className="buttons-workshops">
            <button className="register-btn-workshop" onClick={handleWorkshop2}>
              <strong>Learn More</strong>
            </button>
            <button
              className="register-btn-workshop"
              onClick={handleGetWorkshops}
            >
              <strong>Register Here</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Workshops;
