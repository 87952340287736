import React from "react";
import styled from "styled-components";
import HeadingOne from "../HeadingOne";

// Styled components for the sections
const Section = styled.section`
  padding-right: 500px;
  width: 100vw;
  height: 100vh;
  padding: 1rem 500px 1rem 1rem;
  display: flex;
  align-items: center;
  scroll-snap-stop: always;
  scroll-snap-align: center;

  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      ${(props) => props.startColor || "#09f"},
      ${(props) => props.stopColor || "#90f"}
    ),
    url(${(props) =>
      props.bgImage || "https://picsum.photos/2016/1642?image=10"});
  background-attachment: fixed;
  background-blend-mode: overlay, exclusion;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.7;
  padding: 2vw 3vw 2vw 5vw;
  background: rgb(0 0 0 / 0.6);
  border-radius: 8px;
  backdrop-filter: blur(5px) sepia(0.9) hue-rotate(145deg)
    drop-shadow(5px 9px 24px lime);
  filter: drop-shadow(12px 12px 2px rgb(100 134 45 /0.3));
`;

const Heading = styled.h2`
  font-size: clamp(2rem, 1vw + 2.5rem, 3rem);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
  margin-bottom: 2rem;
`;

// Main React component
const YourComponent = () => {
  return (
    <>
      <Section
        startColor="#00deff"
        stopColor="#ff8c00"
        bgImage="https://picsum.photos/2016/1642?image=10"
      >
        <Content>
          <HeadingOne text1={"Events"} />
          <Heading>Lighting</Heading>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
            dolor beatae, laudantium eos fugiat, deserunt delectus quibusdam
            quae placeat, tempora ea? Nulla ducimus, magnam sunt repellendus
            modi, ad ipsam est.
          </p>
        </Content>
      </Section>

      <Section
        startColor="#111340"
        stopColor="#4800ff"
        bgImage="https://picsum.photos/2016/1642?image=20"
      >
        <Content>
          <Heading>Art & Decor</Heading>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
            dolor beatae, laudantium eos fugiat, deserunt delectus quibusdam
            quae placeat, tempora ea? Nulla ducimus, magnam sunt repellendus
            modi, ad ipsam est.
          </p>
        </Content>
      </Section>
      <Section
        startColor="#111340"
        stopColor="#4800ff"
        bgImage="https://picsum.photos/2016/1642?image=10"
      >
        <Content>
          <Heading>Art & Decor</Heading>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
            dolor beatae, laudantium eos fugiat, deserunt delectus quibusdam
            quae placeat, tempora ea? Nulla ducimus, magnam sunt repellendus
            modi, ad ipsam est.
          </p>
        </Content>
      </Section>

      {/* Repeat the pattern for other sections... */}
    </>
  );
};

export default YourComponent;
