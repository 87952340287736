import React from "react";
import styled, { keyframes } from "styled-components";
import { FaCheckCircle, FaHandHoldingHeart } from "react-icons/fa";

const FixedButtonsContainer = styled.div`
  position: fixed;
  top: 5x;
  right: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const FixedButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 10px;
  color: #fff;
  background-color: #014a82;
  padding: 10px;

  &:hover {
    text-decoration: underline;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const TickerContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 85px;
  right: 0;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  z-index: 999;
  background: rgb(27, 27, 27);

  @media (max-width: 768px) {
    top: 1vh;
    left: 0%;
  }
`;

const Title = styled.div`
  position: absolute;
  background: #df2020;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  color: white;
  font-weight: bold;
  z-index: 200;
`;

const scroll = keyframes`
  from {
    transform: translateX(calc(30% + 100%));
  }

  to {
    transform: translateX(calc(30% - 100%));
  }
`;

const TickerList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  animation: ${scroll} 25s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const TickerItem = styled.li`
  white-space: nowrap;
  padding: 10px 24px;
  color: #fff;
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background: #b8b8b8;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:last-child::after {
    display: none;
  }
`;

const ScrollingTicker = () => {
  return (
    <>
      {/* <FixedButtonsContainer>
        <FixedButton
          href="https://unstop.com/p/sankalan-24-iit-kanpur-civil-engineering-conclave-society-of-civil-engineers-iit-kanpur-844899"
          className="register-tag"
          target="_blank"
        >
          <FaCheckCircle size={20} style={{ marginRight: "5px" }} />
          <span className="tagline">Register Here</span>
        </FixedButton>

        <FixedButton href="/contribution" className="alum-support-tag">
          <FaHandHoldingHeart size={20} style={{ marginRight: "5px" }} />
          <span className="tagline">Alum Support</span>
        </FixedButton>
      </FixedButtonsContainer> */}
      <TickerContainer>
        {/* <Title>Attention</Title> */}
        <TickerList>
          <TickerItem>
            🚀{" "}
            <a
              href="https://unstop.com/p/sankalan-24-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-844899"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#014a82" }}
            >
              Registrations
            </a>{" "}
            is live ! 🌟
          </TickerItem>
          <TickerItem>
            🎉 Registration for{" "}
            <a
              href="/competitions"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#014a82" }}
            >
              Competitions
            </a>{" "}
            and{" "}
            <a
              href="/ppc"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#014a82" }}
            >
              Paper Presentation
            </a>{" "}
            is now open! 📝✨
          </TickerItem>
          <TickerItem>
            🚀 Don't miss out!{" "}
            <a
              href="https://ca.sankalaniitk.in/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#014a82" }}
            >
              The CA Program
            </a>{" "}
            is now live! 🎉 Register now and Seize the opportunity to represent
            your college! 📚✨
          </TickerItem>
        </TickerList>
      </TickerContainer>
    </>
  );
};

export default ScrollingTicker;
