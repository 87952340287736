import "./Terms.css";
import { motion } from "framer-motion";

const Terms = () => {
  return (
    <section id="terms">
      <div className="termsback w-full h-screen dep-about dep-terms">
        {/*  aboutBox is treated as universal */}
        <div
          className="border border-1 bg-transparent aboutbox"
          style={{ background: "rgba(67, 0, 36, 0.3)" }}
        >
          <div className="abouthead align-center ">
            <span
              style={{ fontFamily: "Nasalization", fontSize: "30.22px" }}
              className="hed text-white mx-auto"
            >
              Terms & Conditions
            </span>
          </div>
          <div className="w-auto text-white dep-about-content  flex">
            <ul>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  1. Stall Owners -
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                {" "}
                Only the registered team of participants can be allowed in the
                stall. No family, friends, or others who did not register are
                permitted. Accommodation will be limited to three people per
                start-up.
              </p>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  2. Payment Terms –{" "}
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                {" "}
                For the event, all payments have to be made in advance.
              </p>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  3. Cancellation Policy:{" "}
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                {" "}
                Any cancellation needs to be made in writing addressing the
                organizer as per the below:
                <ul style={{ paddingLeft: "30px" }}>
                  <li>
                    • If the cancellation is done before{" "}
                    <b>24th January 2024, 20%</b> will be deducted on refund.
                  </li>
                </ul>
              </p>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  4. Pack in and Pack out:
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                <ul style={{ paddingLeft: "30px" }}>
                  <li>
                    <b>• Setup Time –</b> All startups will be allowed{" "}
                    <b>2 hours</b> to set up their stalls before the event
                    starts.
                  </li>
                  <li>
                    <b>• Pack up Time –</b> All startups are required to
                    <b> close by 6:00 pm</b> on either day of the event.
                  </li>
                </ul>
              </p>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  5. Materials –{" "}
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                {" "}
                All banners/boards and other collateral, display products etc.
                charges will be borne by the individual startup including their
                transport to and from the event venue.
              </p>
              <li>
                {" "}
                <span
                  className="infoofdv"
                  style={{ fontWeight: "bold", color: "#E75480 " }}
                >
                  6. Accommodation –{" "}
                </span>
              </li>
              <p className="" style={{ textAlign: "justify" }}>
                {" "}
                Accommodation will only be provided till 28th. Beyond that,
                startups will have to manage on their own.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;
