import React from "react";
import "./Team.css";
import {
  FaLinkedin,
  FaFacebook,
  FaHome,
  FaMailBulk,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
} from "react-icons/fa";

import pghosh from "./Core-Team/priyanka-ghosh-iitk.jpg";
import balaji from "./Core-Team/Balaji-Devaraju.jpg";
import partha from "./Core-Team/parthasir.jpeg";
import cksahu from "./Core-Team/cksahu.jpg";
import prabin from "./Core-Team/Prabin-Ashish.jpg";
import shubham from "./Core-Team/shubham.jpeg";
import shrey from "./Core-Team/shrey.jpg";
import sandipan from "./Core-Team/sandipan.jpg";
import saurabh from "./Core-Team/saurabh.jpg";
import baban from "./Core-Team/baban.jpg";
import aditya from "./Core-Team/aditya.jpg";
import aadya from "./Core-Team/aadya.jpg";
import shambhavi from "./Core-Team/shambhavi.jpg";
import vedant from "./Core-Team/vedant.jpg";
import shubhamphd from "./Core-Team/shubhamphd.jpg";
import mansa from "./Core-Team/mansa.jpg";

export default function Team({ isMobileView, isExpanded }) {
  const iconSize = 30;
  const getMarginLeft = (isMobileView, isExpanded) => {
    return isMobileView ? "0" : isExpanded ? "220px" : "45px";
  };

  return (
    <>
      <section
        className="contact"
        id="contact"
        style={{
          marginLeft: getMarginLeft(isMobileView, isExpanded),
          overflowX: "scroll",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "60px",
            left: "20px",
            padding: "10px",
            background: "rgba(59, 116, 148, 0.3)",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <a href="/">
            <FaHome style={{ fontSize: "40px", color: "#00FFFF" }} />
          </a>
        </div>
        <h1
          style={{ "font-family": "'Montserrat', sans-serif" }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          OUR TEAM
        </h1>

        <div
          className="cards"
          style={{
            "font-family": "'Montserrat', sans-serif",
          }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {/* <h3>
            HEAD OF <span>DEPARTMENT</span>
          </h3>
          <div className="inner-card">
            <div className="card">
              <div className="image">
                <img src={pghosh} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Priyanka Ghosh </p>

                <p className="email">
                  <i>Head Dept. Civil Engineering</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:priyog@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/priyanka-ghosh-33173314/?originalSubdomain=in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://iitk.ac.in/new/priyanka-ghosh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
          </div> */}
          <h3>
            CONCLAVE FACULTY <span>MEMBERS</span>
          </h3>
          <div className="inner-card">
            <div className="card">
              <div className="image">
                <img src={cksahu} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Chunendra Sahu </p>

                <p className="email">
                  <i>Conclave Coordinator</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:cksahu@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/chunendra-k-sahu-b90aa33a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/chunendra-k-sahu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={partha} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Partha Mishra</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:pnmishra@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://au.linkedin.com/in/parthanmishra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/partha-narayan-mishra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={prabin} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Prabin Kumar</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>

              <div className="links">
                <a
                  href="mailto:pkashish@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://in.linkedin.com/in/prabin-kumar-ashish-94197b18"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.iitk.ac.in/new/prabin-kumar-ashish"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={balaji} alt="" />
              </div>
              <div className="text">
                <p className="name">Dr. Balaji Devaraju</p>

                <p className="email">
                  <i> Executive Member</i>
                </p>
              </div>

              <div className="links">
                <a
                  href="mailto:dbalaji@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://iitk.ac.in/new/balaji-devaraju"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaGlobe size={iconSize} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="cards"
          style={{ "font-family": "'Montserrat', sans-serif" }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <h3>
            CONCLAVE STUDENT CORE <span>TEAM</span>
          </h3>
          <div className="inner-card">
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={shubham} alt="" />
              </div>
              <div className="text">
                <p className="name">Shubham Kumar </p>

                <p className="email">
                  <i>Overall Coordinator</i>
                </p>
                <p className="no">
                  <i>+91-8009117479</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:shubhamku20@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/mwlite/in/shubham-kumar-9926b9209"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/shubham.kumar_007/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={shrey} alt="" />
              </div>
              <div className="text">
                <p className="name">Shrey Patel</p>

                <p className="email">
                  <i>Head,Marketing</i>
                </p>
                <p className="no">
                  <i>+91-9695970917</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:shreypatel21@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/shrey-patel-830bb164/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/shreypatel1408/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={sandipan} alt="" />
              </div>
              <div className="text">
                <p className="name">Sandipan Dutta</p>

                <p className="email">
                  <i>Head, Events</i>
                </p>
                <p className="no">
                  <i>+91-7906379797</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:sadipand21@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/sandipan-dutta-6a03b6237/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/imsandipandutta03/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={saurabh} alt="" />
              </div>
              <div className="text">
                <p className="name">Saurabh Meena </p>

                <p className="email">
                  <i>Head, Events</i>
                </p>
                <p className="no">
                  <i>+91-8700679804</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:msaurabh21@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/saurabh-meena-94600223b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://instagram.com/saurabhmeena03?igshid=MzNlNGNkZWQ4Mg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={baban} alt="" />
              </div>
              <div className="text">
                <p className="name">Baban Kumar </p>

                <p className="email">
                  <i>Head, Events</i>
                </p>
                <p className="no">
                  <i>+91-9801915141</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:babank@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/imbaban/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://instagram.com/im_baban?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={shambhavi} alt="" />
              </div>
              <div className="text">
                <p className="name">Shambhavi Agrawal </p>

                <p className="email">
                  <i>Head, Public Relations</i>
                </p>
                <p className="no">
                  <i>+91-809705716</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:shambhavi21@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/shambhavi-agarwal-15b47b242/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.instagram.com/i_shambhavi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={aditya} alt="" />
              </div>
              <div className="text">
                <p className="name">Aditya Mishra </p>

                <p className="email">
                  <i>Head, Public Relations</i>
                </p>
                <p className="no">
                  <i>+91-7982617173</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:adityam22@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/aditya-mishra-97042b19b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://instagram.com/a_dity_a_04?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={mansa} alt="" />
              </div>
              <div className="text">
                <p className="name">Mansha Mushtaq </p>

                <p className="email">
                  <i>Head, Public Relations</i>
                </p>
                <p className="no">
                  <i>+91-9541693800</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:mansha@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={iconSize} />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={shubhamphd} alt="" />
              </div>
              <div className="text">
                <p className="name">Shubham Raj</p>

                <p className="email">
                  <i>Head, Hospitality</i>
                </p>
                <p className="no">
                  <i>+91-9532065031</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:shubhce@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/shubham-raj-iitk?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://instagram.com/rajsri2705?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={aadya} alt="" />
              </div>
              <div className="text">
                <p className="name">Aadya Umrao </p>

                <p className="email">
                  <i>Head, Design</i>
                </p>
                <p className="no">
                  <i>+91-9027344063</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:aadya04umrao@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/aadya-umrao-b63b9922b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://www.secure.instagram.com/_aadyaaa_11/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
            <div className="card" style={{ marginBottom: "20px" }}>
              <div className="image">
                <img src={vedant} alt="" />
              </div>
              <div className="text">
                <p className="name">Vedant Adlak </p>

                <p className="email">
                  <i>Head, Web</i>
                </p>
                <p className="no">
                  <i>+91-7000305702</i>
                </p>
              </div>
              <div className="links">
                <a
                  href="mailto:vedanta21@iitk.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={iconSize} />
                </a>
                <a
                  href="https://www.linkedin.com/in/vedant-adlak-4158a8257"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={iconSize} />
                </a>
                <a
                  href="https://instagram.com/vedant_adlak21?igshid=NGExMmI2YTkyZg=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={iconSize} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
