import Term from "./Terms";
import "./slabs.css";
import map from "./map.png";
import Accomodation from "./../Accomodation/Accomodation";

const Slabs = () => {
  return (
    <section id="map">
      <div className="whyback">
        {/* Centered heading */}
        <span
          style={{
            fontSize: "46.22px",
            fontFamily: "Nasalization",
            textAlign: "center",
            display: "block",
            margin: "0 auto",
            marginTop: "40px",
          }}
          className="hed"
        >
          EXHIBITION STALS
        </span>

        {/* Image placeholder */}
        {/* <div className="map-img" style={{ backgroundImage: `url(${map})` }}>
          
        </div> */}
      </div>

      {/* Slabs section with spacing */}
      <div className="slabs">
        <div
          className="border whybox slab1"
          style={{ marginRight: "10px", width: "45vw" }}
        >
          <span
            style={{
              fontSize: "28.22px",
              fontFamily: "Nasalization",
            }}
            className="text-white mx-auto"
          >
            Gold Stall:{"  "}
          </span>
          <span
            style={{ fontSize: "28.22px", fontFamily: "Nasalization" }}
            className="text-white mx-auto"
          >
            ₹18,000
          </span>
          <hr />
          <div className="w-auto text-white flex whyboxcontent">
            <ul>
              <li> ◾ Size - 3m x 3m</li>
              <li>◾ LED Screen</li>
              <li>◾ Table</li>

              <li>◾ Chair </li>
              <li>◾ Light </li>
              <li>◾ Plug</li>
              <li>◾ Dustbin</li>
              <li>◾ Accomodation upto 2 days</li>
              <li>◾ Banner with Startup Name</li>
              <li>◾ Events and Networking Lunch Pass</li>
            </ul>
          </div>
        </div>

        <div
          className="border whybox slab2  "
          style={{ marginRight: "10px", width: "45vw" }}
        >
          <div className=" grid  ">
            <span
              style={{ fontFamily: "Nasalization", fontSize: "28.22px" }}
              className="text-white mx-auto"
            >
              Silver Stall:{"    "}
            </span>
            <span
              style={{ fontFamily: "Nasalization", fontSize: "28.22px" }}
              className="text-white mx-auto"
            >
              ₹15,000
              <hr />
            </span>
          </div>
          <div className="w-auto text-white flex whyboxcontent">
            <ul>
              <li> ◾ Size - 3m x 3m</li>

              <li>◾ Table</li>

              <li>◾ Chair </li>
              <li>◾ Light </li>
              <li>◾ Plug</li>
              <li>◾ Dustbin</li>
              <li>◾ Accomodation upto 2 days</li>
              <li>◾ Banner with Startup Name</li>
              <li>◾ Events and Networking Lunch Pass</li>
            </ul>
          </div>
        </div>

        {/* ... Repeat for SLAB 2 with similar structure and spacing ... */}
      </div>
    </section>
  );
};

export default Slabs;
