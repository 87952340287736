import Slabs from "./slabs";
import Terms from "./Terms";
import { getMarginLeft } from "../../utils/getMarginLeft";

const ExhibitionStals = ({ isMobileView, isExpanded }) => {
  return (
    <div
      className=".exhibition-stals"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <Slabs />
      <Terms />
    </div>
  );
};

export default ExhibitionStals;
