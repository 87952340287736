import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ComingSoonPage from "./components/ComingsoonPage";
import AlumSupport from "./components/Alum/alum";
import Team from "./components/Team/Team";
import { Pre_Events } from "./components/Pre-Events/pre-events";
import { Projects } from "./components/Projects";
import Workshops from "./components/Workshops/Workshops";
import ExhibitionStals from "./components/Slabs/ExhibitionStals";

import YourComponent from "./components/Test";
import Accomodation from "./components/Accomodation/Accomodation";
import MobileNavbar from "./components/MobileNavbar/MobileNavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import ScrollingTicker from "./components/Scrolling_Ticker";
import PaperPresentation from "./PaperPresentation/PaperPresentation";
import Competitions from "./components/Competitions/Competitions";
import Sponsors from "./components/Sponsors/Sponsors";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppContainer = () => {
  const [isExpanded, setExpandedState] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileView = windowWidth <= 768;

  return (
    <BrowserRouter>
      <React.StrictMode>
        <ScrollingTicker />
        {isMobileView ? (
          <MobileNavbar />
        ) : (
          <SideNavBar
            isExpanded={isExpanded}
            setExpandedState={setExpandedState}
          />
        )}

        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/comingsoon" element={<ComingSoonPage />} />
          <Route path="/notifications" element={<ComingSoonPage />} />
          <Route path="/festschedule" element={<ComingSoonPage />} />
          <Route path="/events" element={<ComingSoonPage />} />
          <Route
            path="/workshops"
            element={
              <Workshops isMobileView={isMobileView} isExpanded={isExpanded} />
            }
          />
          {/* <Route path="/sponsors" element={<ComingSoonPage />} /> */}

          <Route
            path="/sponsors"
            element={
              <Sponsors isMobileView={isMobileView} isExpanded={isExpanded} />
            }
          />
          <Route
            path="/team"
            element={
              <Team isMobileView={isMobileView} isExpanded={isExpanded} />
            }
          />
          <Route
            path="/contribution"
            element={
              <AlumSupport
                isMobileView={isMobileView}
                isExpanded={isExpanded}
              />
            }
          />
          <Route path="/register" element={<ComingSoonPage />} />
          <Route
            path="/exhibitions"
            element={
              <ExhibitionStals
                isMobileView={isMobileView}
                isExpanded={isExpanded}
              />
            }
          />
          <Route
            path="/ppc"
            element={
              <PaperPresentation
                isMobileView={isMobileView}
                isExpanded={isExpanded}
              />
            }
          />
          <Route path="/test" element={<YourComponent />} />
          <Route
            path="/accommodation"
            element={
              <Accomodation
                isMobileView={isMobileView}
                isExpanded={isExpanded}
              />
            }
          />
          <Route
            path="/pre-events"
            element={
              <Pre_Events isMobileView={isMobileView} isExpanded={isExpanded} />
            }
          />
          <Route
            path="/projects"
            element={
              <Projects isMobileView={isMobileView} isExpanded={isExpanded} />
            }
          />
          <Route
            path="/competitions"
            element={
              <Competitions
                isMobileView={isMobileView}
                isExpanded={isExpanded}
              />
            }
          />
        </Routes>
      </React.StrictMode>
    </BrowserRouter>
  );
};

root.render(<AppContainer />);

reportWebVitals();
