import React from "react";
import styled, { keyframes } from "styled-components";

const waveAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1600px 0;
  }
`;

const CompetitionBlock = styled.div`
  background-color: black;
  .ag-Competition_title {
    padding: 100px 0;
    background-color: #00a2ff;
    text-align: center;
    font-family: "Times New Roman", sans-serif;
    font-weight: bold;
    font-size: 120px;
    color: #fff;
    position: relative;

    &:before {
      content: "";
      height: 125px;
      width: 100%;
      background: url(https://raw.githack.com/SochavaAG/example-mycode/master/pens/animation-wave-shadow/images/wave.svg)
        repeat-x scroll 0 0;
      background-size: 1600px 198px;
      position: absolute;
      left: 0;
      bottom: 0;
      filter: drop-shadow(5px -10px 3px rgba(0, 0, 0, 0.2));
      animation: ${waveAnimation} 20s linear infinite;
    }
  }

  @media screen and (max-width: 768px) {
    .ag-Competition_title {
      font-size: 40px;
    }
  }
`;

const HeadingComponent = () => {
  return (
    <CompetitionBlock>
      {/* <HeavitasFont /> */}
      <div className="ag-Competition_title">
        <div className="ag-format-container">Competitions</div>
      </div>
    </CompetitionBlock>
  );
};

export default HeadingComponent;
