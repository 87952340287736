import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Button = styled.button`
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 0.8rem;
  letter-spacing: 2px;
  cursor: pointer;
  background: transparent;
  color: rgba(0, 212, 255, 0.9);
  border: 1px solid rgba(0, 212, 255, 0.6);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.125);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
  }
`;

const CardButton = ({ link }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Redirect to the specified link
    navigate(link);
  };

  return <Button onClick={handleButtonClick}>DETAILS</Button>;
};

const CardButton2 = ({ link }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Open the link in a new tab
    window.open(link, "_blank");
  };

  return <Button onClick={handleButtonClick}>DETAILS</Button>;
};

const Container = styled.div`
  background-color: black;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 400px) and (max-width: 1439px) {
    max-width: 1279px !important;
    padding: 0 !important;
    margin: 0 80px !important;
    width: auto !important;
  }

  @media screen and (max-width: 400px) {
    max-width: 100%;
    margin: 0 16px !important;
    padding: 0 !important;
    width: auto !important;
  }
`;

const GradientCards = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  padding: 16px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 30px;
  }

  @media screen and (max-width: 400px) {
    gap: 16px;
    padding: 16px;
  }
`;

const ContainerTitle = styled.p`
  text-align: center;
  padding: 0;
  margin-bottom: 2px;
  font-size: 30px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-family: "Times Roman", serif;
  line-height: 40px;

  @media screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 60px;
  }
`;

const Card = styled.div`
  max-width: 100%;
  border: 0;
  width: 100%;
  margin-inline: auto;
`;

const ContainerCard = styled.div`
  position: relative;
  border: 1px solid transparent;
  background: linear-gradient(156deg, #0d0d0d, #0f0e10, #0d0d0d);
  background-clip: padding-box;
  border-radius: 10px;
  padding: 16px;

  @media screen and (min-width: 992px) {
    border-radius: 16px;
    padding: 30px;
  }
`;

const BackgroundBox = styled.div`
  position: relative;
  border: 2px solid transparent;
`;

const BackgroundBoxAfter = styled.div`
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  content: "";
  z-index: -1;
  border-radius: 24px;

  @media screen and (min-width: 992px) {
    border-radius: 45px;
  }
`;

const GreenBoxAfter = styled(BackgroundBoxAfter)`
  background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
`;

const CardTitle = styled.p`
  font-weight: 600;
  color: white;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-style: normal;
  font-size: 19px;

  padding-bottom: 8px;
  font-family: "Times Roman", serif;

  @media screen and (min-width: 992px) {
    font-size: 20px;
    line-height: 40px;
  }
`;

const CardDescription = styled.p`
  font-weight: 50;
  letter-spacing: 0.4px;
  line-height: 20px;
  font-size: 15px; /* Default font size for screens below 992px */

  color: hsla(0, 0%, 100%, 0.6);
  max-width: 470px;
  text-align: justify;
  font-family: "Times Roman", serif;

  @media screen and (max-width: 768px) {
    font-size: 13px; /* Font size for screens 992px and above */
    line-height: 18px;
  }
`;

function FeatureCards() {
  return (
    <section className="events" id="events">
      <Container>
        <ContainerWrapper>
          <ContainerTitle>Events</ContainerTitle>

          <GradientCards>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>PRE-EVENTS</CardTitle>
                  <CardDescription>
                    Numerous thoughtfully organized events and initiatives aim
                    to foster community engagement and excitement, with a rich
                    calendar spanning from October to January. Events include
                    Cricket Tournament, Bicycling Event, Running Event, Nature
                    Awareness Drive, Donation Drive, Education Drive.
                  </CardDescription>
                  <CardButton link="/pre-events" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>TALKS</CardTitle>
                  <CardDescription>
                    Prominent figures from academia and industry, hailing from
                    both national and international backgrounds, will present
                    talks on cutting-edge developments in civil engineering.
                    These discussions will delve into career opportunities and
                    prospects in the field.
                  </CardDescription>
                  <CardButton link="/comingsoon" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>COMPETITIONS</CardTitle>
                  <CardDescription>
                    A dynamic platform featuring highly innovative problem
                    statements, curated by the esteemed faculty of IIT Kanpur,
                    is being created for a nationwide student event. Real-world
                    problem-solving challenges covering all major domains of
                    civil engineering will be offered, allowing participants to
                    showcase their talents and apply their analytical and
                    practical civil engineering knowledge
                  </CardDescription>
                  <CardButton link="/competitions" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>OUTREACH</CardTitle>
                  <CardDescription>
                    We will conduct promotional events including workshops,
                    competitions and goodies in educational institutions near
                    Lucknow and Kanpur . Our primary objective is to disseminate
                    information and enhance awareness regarding an upcoming
                    conclave, concurrently emphasizing the diverse educational
                    and career prospects we offer to students throughout
                    conclave{" "}
                  </CardDescription>
                  <CardButton2 link="https://www.instagram.com/p/C2Pe2ogIujS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>WORKSHOPS</CardTitle>
                  <CardDescription>
                    Industry experts will conduct professional workshops
                    sponsored and certified by us. These hands-on sessions are
                    carefully designed to equip participants with the latest
                    knowledge and skills essential for success in the
                    ever-evolving field of civil engineering
                  </CardDescription>
                  <CardButton link="/workshops" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>EXHIBITIONS</CardTitle>
                  <CardDescription>
                    In a display of ingenuity and cooperation, emerging startups
                    in the sustainability and eco-friendly sectors within civil
                    engineering, along with established industry leaders, will
                    present their offerings to the IITK community. This
                    exhibition serves as a venue for these businesses to
                    interact with a knowledgeable audience, nurturing
                    connections and potential collaborations.
                  </CardDescription>
                  <CardButton link="/exhibitions" />
                </BackgroundBox>
              </ContainerCard>
            </Card>

            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>PAPER PRESENTATION COMPETITION</CardTitle>
                  <CardDescription>
                    This competition serves as a golden opportunity for aspiring
                    researchers across the nation to spotlight their dedication
                    and innovation before both our discerning audience and
                    esteemed panel of professors. We warmly welcome abstract
                    submissions for this prestigious event. Those who shine in
                    the online submission round will earn the chance to present
                    their groundbreaking work on our campus.
                  </CardDescription>
                  <CardButton link="/ppc" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
            <Card>
              <ContainerCard>
                <BackgroundBox className="bg-green-box">
                  <GreenBoxAfter />
                  {/* Add your icon here */}
                  <CardTitle>CULTURAL NIGHT</CardTitle>
                  <CardDescription>
                    We are orchestrating a compelling series of events featuring
                    exceptionally talented artists, meticulously curated to
                    offer our participants a joyous and invigorating experience.
                    Our aim is to provide them with a brief respite from their
                    demanding daily routines, enabling them to savor moments of
                    pure happiness while in our esteemed establishment.
                  </CardDescription>
                  <CardButton link="/comingsoon" />
                </BackgroundBox>
              </ContainerCard>
            </Card>
          </GradientCards>
        </ContainerWrapper>
      </Container>
    </section>
  );
}

export default FeatureCards;
