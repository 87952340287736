import React from "react";
import {
  FaHome,
  FaUsers,
  FaCalendarAlt,
  FaCalendar,
  FaExhibit,
  FaTools,
  FaHandshake,
  FaUserFriends,
  FaRegClock,
  FaTrophy,
  FaChalkboardTeacher,
  FaBed,
  FaPallet,
  FaStore,
} from "react-icons/fa";
import "./SideNavBar.css";
import logo from "./sankalan-logo.png";
import { useState } from "react";

const SideNavBar = ({ isExpanded, setExpandedState }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuItems = [
    {
      text: "Home",

      icon: <FaHome size={24} />,
      link: "/",
    },
    {
      text: "Schedule",
      icon: <FaCalendar size={24} />,
      link: "https://drive.google.com/file/d/1MC570wz2Lh5CxBZPWQnvIu69wa-5Fjyq/view",
    },
    {
      text: "Ambassador",
      icon: <FaUsers size={24} />,
      link: "https://ca.sankalaniitk.in/",
    },
    // {
    //   text: "Events",
    //   icon: <FaCalendarAlt size={24} />,
    //   link: "#events",
    // },
    {
      text: "Pre-Events",
      icon: <FaRegClock size={24} />,
      link: "/pre-events",
    },
    {
      text: "Competitions",
      icon: <FaTrophy size={24} />,
      link: "/competitions",
    },
    {
      text: "Presentation ",
      icon: <FaChalkboardTeacher size={24} />,
      link: "/ppc",
    },
    {
      text: "Workshops",
      icon: <FaTools size={24} />,
      link: "/workshops",
    },
    {
      text: "Exhibitions",
      icon: <FaStore size={24} />,
      link: "/exhibitions",
    },
    {
      text: "Accomodation",
      icon: <FaBed size={24} />,
      link: "/accommodation",
    },

    {
      text: "Sponsors",
      icon: <FaHandshake size={24} />,
      link: "/sponsors",
    },
    {
      text: "Our Team",
      icon: <FaUserFriends size={24} />,
      link: "/team",
    },
  ];

  const toggleSidebar = () => {
    setExpandedState(!isExpanded);
  };

  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
      style={{ background: isExpanded ? "#012d51" : "#014a82", zIndex: "1000" }}
    >
      <div
        className="nav-upper"
        style={{
          height: "100vh",
          // border: "2px solid orange",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <a href="/" className="navbar-brand fs-26">
                <img
                  src={logo}
                  alt="Sankalan-CA"
                  className="logo-image"
                  style={{
                    minWidth: "230px",
                    maxHeight: "50px",
                    marginLeft: "3px",
                    paddingTop: "5px",
                    marginRight: "10px",
                  }}
                />{" "}
              </a>
              {/* <h2 style={{ padding: "10px", margin: "10px" }}>Sankalan</h2> */}
            </div>
          )}
          <button
            className={
              isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
            }
            onClick={toggleSidebar}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div
          className="nav-menu"
          style={{
            // marginTop: "-250px",
            // border: "2px solid green",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // gap: "6vh",
          }}
        >
          {menuItems.map(({ text, icon, link }) => (
            <a
              className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
              href={link}
              key={text}
              onMouseOver={() => {
                setIsHovered(true);
                setHoveredItem(text);
              }}
              onMouseOut={() => {
                setIsHovered(false);
                setHoveredItem(null);
              }}
              style={{
                // border: "2px solid red",
                height: "5vh",
              }}
            >
              <span
                className="menu-item-icon"
                style={{
                  marginRight: "8px",
                  gap: "2vh",
                }}
              >
                {icon}
              </span>
              <div style={{ position: "relative" }}>
                {isExpanded ? (
                  <p style={{ display: "block" }}>{text}</p>
                ) : (
                  <p
                    style={{
                      visibility:
                        hoveredItem === text && !isExpanded
                          ? "visible"
                          : "hidden",
                      position: "absolute",
                      top: "0%",
                      left: "50%",
                      marginLeft: "0px",
                      zIndex: "10",
                      fontSize: "12px",
                      width: "120px",
                      backgroundColor: "#333",
                      color: "#fff",
                      textAlign: "center",
                      padding: "4px",
                      borderRadius: "4px",
                      transition: "opacity 0.05s",
                    }}
                  >
                    {text}
                  </p>
                )}
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
