import React from "react";
import { ProjectCard } from ".././ProjectCard";
import { FaHome } from "react-icons/fa";
import { Row } from "react-bootstrap";
import hydro_ps1 from ".././utils_competitions/Hydro_PS1.png";
import str_ps1 from ".././utils_competitions/Str_PS1.png";
import geo_ps1 from ".././utils_competitions/geo_ps_1.png";
import crack_ps from ".././utils_competitions/crack_ps.png";
import "./Competitions.css";
import { getMarginLeft } from "../../utils/getMarginLeft";

function Competitions({ isMobileView, isExpanded }) {
  const handleGetCompetitions = () => {
    window.open(
      "https://unstop.com/p/sankalan-24-iit-kanpur-civil-engineering-conclave-society-of-civil-engineers-iit-kanpur-844899",
      "_blank"
    );
  };

  const competitions = [
    {
      title: "Spaghetti Bridge Design Challenge",
      description:
        "Get ready to exhibit your analytical and design prowess in the Bridge Design Competition. Craft tension-compression members to maximize the load bearing capability of your truss bridge . Join us for this challenge emphasizing innovation and structural efficiency!",
      last_date: "18th January",
      imgUrl: str_ps1,
      ps_link:
        "https://docs.google.com/document/d/11x2RRDmE5qCYvqWXTA4b2jemZsyKQv5IrCiqE0YIPr8/edit?usp=drive_link",
      reg_link:
        "https://unstop.com/p/spaghetti-bridge-design-challenge-sankalan-24-iit-kanpur-society-of-civil-engineers-iit-kanpur-846090",
    },
    {
      title: "Optimising Water Resources ",
      description:
        "As we know that water scarcity is a pressing global issue, especially in the context of agricultural sustainability. Gear up your sustainable mindset and develop an innovative solution to optimise water resource usage for sustainable agriculture. Pull up your socks and show us your creative sustainable models.",
      last_date: "18th January",
      imgUrl: hydro_ps1,
      ps_link:
        "https://docs.google.com/document/d/1uxHCXVE8-34AcAetIYmUkNRDI618si0lvfYL66KqkCo/edit?usp=drive_link",
      reg_link:
        " https://unstop.com/p/optimising-water-resources-for-sustainable-agriculture-sankalan-24-civil-conclave-iit-kanpur-848899",
    },
    {
      title: "Remote Satellite Design Challenge",
      description:
        "Exclusive opportunity for all the geoinformatics enthusiasts to showcase your talent via practical implementation. Build a remote satellite model that measures any essential climate variable (ECV) and pounce upon the opportunity to compete with like minds across the country.",
      last_date: "18th January",
      imgUrl: geo_ps1,
      ps_link:
        "https://docs.google.com/document/d/1jo13ulpLOMo076Avr8urmD8w2WT9AHsC4TRjFRp9ra0/edit?usp=drive_link",
      reg_link:
        " https://unstop.com/p/remote-satellite-design-challenge-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-850526",
    },
    {
      title: "Crack Detection Challenge",
      description:
        "This assignment challenges participants to solve an image classification problem using deep convolutional neural networks. The primary objective is to develop a binary image classification model capable of determining whether a given image contains any cracks.",
      last_date: "18th January",
      imgUrl: crack_ps,
      ps_link:
        "https://docs.google.com/document/d/1fi9ajupzD3RAGRBzJJOlj4z9uJTwkotQsNaehJMokQM/edit#heading=h.p8dw0fxji6pf",
      reg_link:
        " https://unstop.com/p/crack-detection-challenge-sankalan-24-civil-conclave-iit-kanpur-858719",
    },
  ];

  return (
    <div
      className="Competitions"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <div className="guidelines-competitions">
        <h4
          style={{
            fontWeight: "550",
            marginLeft: "1.5vh",
            marginBottom: "1.5vh",
          }}
        >
          Competitions Guidelines:
        </h4>

        <ul>
          <li>
            Hover over individual challenges to read their problem statements by
            clicking on 'Learn More.'
          </li>
          <li>
            To participate in competitions, click 'Register' to fill out the
            form and pay the fees on the Unstop opportunity platform.
          </li>
          <li>
            Note that registering for the 'Sankalan' event is separate from
            competition registration.
          </li>
          <li>
            To attend the 'Sankalan' event at IIT Kanpur on January 27th-28th,
            2024,{" "}
            <a
              href="https://unstop.com/p/sankalan-24-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-844899"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>click here to register</strong>
            </a>
          </li>
          <li>
            Some competitions have two rounds; if you qualify for Round 2,
            registration fees for 'Sankalan' will be waived. Participants only
            pay for accommodation{" "}
            <a
              href="https://sankalaniitk.in/accomodation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>(see details here)</strong>
            </a>
            . Further details will be provided after Round 1 results.
          </li>
        </ul>
      </div>
      <div className="competition-cards">
        <Row>
          {competitions.map((competition, index) => {
            return <ProjectCard key={index} {...competition} />;
          })}
        </Row>
      </div>
    </div>
  );
}

export default Competitions;
