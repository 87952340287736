import { Container, Row, Col } from "react-bootstrap";

import "animate.css";
import TrackVisibility from "react-on-screen";
import { FaExternalLinkAlt } from "react-icons/fa";

export const CampusAmbassador = () => {
  return (
    <section className="project" id="campusam">
      <Container>
        <Row className="align-items-center">
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2
                    style={{
                      fontFamily: "Times New Roman",
                      fontWeight: "600",
                    }}
                  >
                    Campus Ambassador
                  </h2>
                  <p
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "18px",
                      textAlign: "justify",
                    }}
                  >
                    Introducing the Sankalan Campus Ambassador Program – an
                    incredible opportunity for you to become a valuable
                    representative of Sankalan within your college community. As
                    a Campus Ambassador, you'll collaborate closely with our
                    organizing team, co-host engaging workshops and events on
                    your campus, and be part of something truly special. But the
                    perks don't stop there! We have exciting rewards waiting
                    just for you. So, why let this golden opportunity slip
                    through your fingers? Join us today and embark on an
                    enriching journey as a Sankalan Campus Ambassador!
                  </p>

                  <div className="text-center">
                    {/* <a href="/campusambassador" className="alum-support-tag">
                      <FaCheckCircle size={20} style={{ marginRight: "5px" }} />
                      <span className="tagline">Visit Here Now !!!</span>
                    </a> */}
                    <a
                      href="https://ca.sankalaniitk.in/"
                      className="alum-support-tag"
                    >
                      <FaExternalLinkAlt
                        size={20}
                        style={{ marginRight: "5px" }}
                      />
                      <span className="tagline">Visit Here</span>
                    </a>
                    {/* <Button variant="primary" style={buttonStyle}>
                      <span
                        style={{
                          background:
                            "linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%)",
                          WebkitBackgroundClip: "text",
                        }}
                      >
                        Visit Here Now !!!
                      </span>
                    </Button> */}
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img
        className="background-image-right"
        src={colorSharp2}
        alt="Background"
      /> */}
    </section>
  );
};
