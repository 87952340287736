import React from "react";
import "./Sponsors.css";
import { FaHome } from "react-icons/fa";

import eielogo from "../../assets/img/Sponsors/EIE-Logo-20-07-2020.png";
import heicologo from "../../assets/img/Sponsors/heico.png";
import vemtechlogo from "../../assets/img/Sponsors/vemtech_logo.jpeg";
import antonlogo from "../../assets/img/Sponsors/anton-paar-singapore.jpg";
import civil_DK from "../../assets/img/Sponsors/civil_DK.png";
import civil_concepts from "../../assets/img/Sponsors/Civil_Concepts_Logo.png";
import cortex from "../../assets/img/Sponsors/1.png";
import ventech from "../../assets/img/Sponsors/2.png";
import cobalt from "../../assets/img/Sponsors/3.png";
import deshaj from "../../assets/img/Sponsors/6.png";
import rodic from "../../assets/img/Sponsors/7.png";
import texel from "../../assets/img/Sponsors/texel.jfif";
import unibic from "../../assets/img/Sponsors/9.png";
import biskfarm from "../../assets/img/Sponsors/10.png";
import lwl from "../../assets/img/Sponsors/11.png";
import unseenkanpur from "../../assets/img/Sponsors/12.png";
import kanpurcouple from "../../assets/img/Sponsors/13.png";
import aboutce from "../../assets/img/Sponsors/14.png";

import creatorkanpur from "../../assets/img/Sponsors/15.png";

function SponsorsCard({ logo, sponsorName, sponsorTitle }) {
  return (
    <div className="sponsors-card">
      <div className="sponsors-card-image">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="" style={{ objectPosition: "center" }} />
        </div>

        {/* style={{ borderRadius: "50%", width: "19vw" }} */}
      </div>
      <div className="sponsors-card-text" style={{ textAlign: "center" }}>
        <h1 style={{ fontStyle: "italic", fontSize: "1.5rem" }}>
          {sponsorName}
        </h1>
        <h1 style={{ fontStyle: "italic", fontSize: "1.5rem" }}>
          {sponsorTitle}
        </h1>
      </div>
    </div>
  );
}

export default function Sponsors({ isMobileView, isExpanded }) {
  const iconSize = 30;
  const getMarginLeft = (isMobileView, isExpanded) => {
    return isMobileView ? "0" : isExpanded ? "220px" : "45px";
  };

  return (
    <>
      <section
        className="contact"
        id="contact"
        style={{
          marginLeft: getMarginLeft(isMobileView, isExpanded),
          overflowX: "scroll",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "60px",
            left: "20px",
            padding: "10px",
            background: "rgba(59, 116, 148, 0.3)",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <a href="/">
            <FaHome style={{ fontSize: "40px", color: "#00FFFF" }} />
          </a>
        </div>
        <h1
          style={{ "font-family": "'Montserrat', sans-serif" }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          OUR SPONSORS
        </h1>

        {/* <h3>
          CORE <span>SPONSORS</span>
        </h3> */}
        <div className="inner-card">
          <SponsorsCard
            logo={rodic}
            sponsorName="Rodic"
            sponsorTitle="PLATINUM SPONSOR"
          />
          <SponsorsCard
            logo={antonlogo}
            sponsorName="Anton Par"
            sponsorTitle="GOLD SPONSOR"
          />
          <SponsorsCard
            logo={cortex}
            sponsorName="Cortex"
            sponsorTitle="SILVER SPONSOR"
          />
          <SponsorsCard
            logo={cobalt}
            sponsorName="Cobalt Infra"
            sponsorTitle="SILVER SPONSOR"
          />
          <SponsorsCard
            logo={ventech}
            sponsorName="Ventech"
            sponsorTitle="SILVER SPONSOR"
          />
          <SponsorsCard
            logo={eielogo}
            sponsorName="EIE Instruments"
            sponsorTitle="BRONZE SPONSOR"
          />
        </div>
        <hr />
        <h1
          style={{
            "font-family": "'Montserrat', sans-serif",
            margin: "0.01%",
          }}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          PARTNERS
        </h1>

        <SponsorsCard logo={texel} sponsorName="Texel Consulting" />
        <hr />
        <h3>
          WORKSHOP <span>PARTNERS</span>
        </h3>
        <div className="inner-card">
          <SponsorsCard
            logo={lwl}
            sponsorName="Learning While Travelling"
            sponsorTitle="KNOWLEDGE PARTNER"
          />
          <SponsorsCard
            logo={deshaj}
            sponsorName="Deshaj Labs"
            sponsorTitle="COMPETITION PARTNER"
          />
        </div>
        <hr />
        <h3>
          SNACK <span>PARTNERS</span>
        </h3>
        <div className="inner-card">
          <SponsorsCard logo={unibic} sponsorName="Unibic" />
          <SponsorsCard logo={biskfarm} sponsorName="Bisk Farm" />
        </div>
        <hr />
        <h3>
          SOCIAL MEDIA <span>PARTNERS</span>
        </h3>
        <div className="inner-card">
          <SponsorsCard logo={kanpurcouple} sponsorName="Kanpur Couple" />
          <SponsorsCard logo={unseenkanpur} sponsorName="Unseen Kanpur" />
          <SponsorsCard logo={creatorkanpur} sponsorName="Creator Kanpur" />
          <SponsorsCard logo={aboutce} sponsorName="About Civil Engineering" />

          <SponsorsCard logo={civil_concepts} sponsorName="Civil Concepts" />
          <SponsorsCard logo={civil_DK} sponsorName="Civil Engineering by DK" />
        </div>
        <hr />
        <div className="expo" data-aos="fade-up" data-aos-delay="300">
          <h3 style={{ "font-family": "'Montserrat', sans-serif" }}>
            <a
              href="/contribution"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              ALUM <span>CONTRIBUTIONS</span>{" "}
            </a>
          </h3>
        </div>
        {/* <div className="circle">
          <p>Alum Contribution</p>
        </div> */}
      </section>
    </>
  );
}
