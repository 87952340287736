import React from "react";
import "./PaperPresentation.css";
import { getMarginLeft } from "../utils/getMarginLeft";

function PaperPresentation({ isMobileView, isExpanded }) {
  const handleGetPaperPresentation = () => {
    window.open("https://forms.gle/okPtju5HHEMr9sBq6", "_blank");
  };

  return (
    <div
      className="paper-presentation"
      style={{ marginLeft: getMarginLeft(isMobileView, isExpanded) }}
    >
      <div className="guidelines-ppc">
        <div className="upper-text-btn">
          <div className="ppc-text">
            <h4 style={{ fontWeight: "550" }}>
              Paper Presentation Guidelines:
            </h4>
            <br />
            <p style={{ textAlign: "justify" }}>
              This competition serves as a golden opportunity for aspiring
              researchers across the nation to spotlight their dedication and
              innovation before both our discerning audience and esteemed panel
              of professors. We warmly welcome abstract submissions for this
              prestigious event. Those who shine in the online submission round
              will earn the chance to present their groundbreaking work on our
              campus. Seize this golden opportunity by registering now and
              secure your spot in this intellectually stimulating competition!
            </p>
          </div>
          <div className="ppc-register">
            <div>
              <h3 style={{ fontWeight: "550" }}>Price: Rs. 10,000</h3>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <button
                className="register-btn-ppc"
                onClick={handleGetPaperPresentation}
              >
                <strong>Register Here</strong>
              </button>
            </div>
            <div />
          </div>
        </div>

        <ul>
          <li>
            <strong>Deadline:</strong> 18th January 2024
          </li>
          <li>
            <strong>Eligibility:</strong> Open to all engineering students
            (BTech/BE/MTech/PhD)
          </li>
          <li>
            <strong>Team Limit:</strong> Individual participation only
          </li>
          <li>
            <strong>Incentives:</strong>
          </li>
          <ul>
            <li>
              <strong>Cash Reward: Rs. 10,000</strong>
            </li>
            <li>
              <strong>Certificate and goodies</strong>
            </li>
            <li>
              <strong>
                Selected abstracts will be published in Society of Civil
                Engineers annual civil engineering magazine - Strengths (
                <a
                  href="https://soceiitk.org/strengths"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://soceiitk.org/strengths
                </a>
                ).
              </strong>
            </li>
          </ul>
          <br />
          <br />
          <li>
            <strong>Accepted topics of research:</strong>
          </li>
          <ul>
            <li>Structural Engineering</li>
            <li>Environmental Engineering</li>
            <li>Hydrology and Water Resources Engineering</li>
            <li>Geotechnical Engineering</li>
            <li>Geoinformatics Engineering</li>
            <li>Transportation Engineering</li>
            <li>Construction Management</li>
          </ul>
          <br />
          <br />
          <li>
            <strong>Round 1:</strong> Abstract Submission (Online submission
            only)
          </li>
          <li>
            <strong>Round 2:</strong> Paper Presentation (Offline presentation
            at IIT Kanpur)
          </li>
          <li>
            Participants with shortlisted abstracts will present their research
            to esteemed professors and panels during Sankalan ‘24. Further
            instructions will be conveyed after the release of the results.
          </li>
          <li>
            <strong>Word Limit for Abstract:</strong> 250
          </li>
          <li>
            <strong>Naming Convention:</strong>{" "}
            &lt;Your_phone_number&gt;_&lt;Domain&gt; (e.g.,
            8009117479_structural)
          </li>
        </ul>
        <br />

        <p style={{ fontStyle: "italic" }}>
          Kindly refrain from naming your submission anything else, as it will
          not be considered for evaluation in such a scenario.
        </p>

        <p>
          <strong>Contact:</strong> Pranay (
          <a href="mailto:pranays22@iitk.ac.in">pranays22@iitk.ac.in</a> |{" "}
          <a href="tel:+91-8769639498">+91-8769639498</a>)
        </p>
      </div>
    </div>
  );
}

export default PaperPresentation;
